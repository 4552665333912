import {Routes, Route} from "react-router-dom";
import {AppLayout} from "common/Layout";
import {AdminAndUserPageProtector, AuthorizedPageProtector} from "common/Protectors";
import {AdminPageProtector} from "common/Protectors/PageProtector/AdminPageProtector";
import {homePageRoute} from "./home/config";
import {notFoundPageRoute} from "./404/config";
import {logoutPageRoute} from "./logout/config";
import {authorizationPageRoute} from "./authorization/config";
import {mainProgramsPageRoute} from "./main-programs/config";
import {createMainProgramPageRoute} from "./create-main-program/config";
import {viewMainProgramPageRoute} from "./view-main-program/config";
import {mainProgramTemplatesPageRoute} from "./main-program-temlates/config";
import {viewMainProgramTemplatePageRoute} from "./view-main-program-template/config";
import {previewMainProgramPageRoute} from "./preview-main-program/config";
import {workProgramElementaryPageRoute} from "./work-programs-elementary/config";
import {workProgramGeneralPageRoute} from "./work-programs-general/config";
import {workProgramSecondaryPageRoute} from "./work-programs-secondary/config";
import {mainProgramPageRoute} from "./main-program/config";

export const AppRouting = () => {
  return (
    <Routes>
      <Route element={<AuthorizedPageProtector/>}>

        <Route element={<AppLayout/>}>
          <Route path={homePageRoute.path} element={homePageRoute.element}/>
          <Route path={mainProgramsPageRoute.path} element={mainProgramsPageRoute.element}/>
          <Route element={<AdminAndUserPageProtector/>}>
            <Route path={workProgramElementaryPageRoute.path} element={workProgramElementaryPageRoute.element}/>
            <Route path={workProgramGeneralPageRoute.path} element={workProgramGeneralPageRoute.element}/>
            <Route path={workProgramSecondaryPageRoute.path} element={workProgramSecondaryPageRoute.element}/>
            <Route path={createMainProgramPageRoute.path} element={createMainProgramPageRoute.element}/>
          </Route>
          <Route element={<AdminPageProtector/>}>
            <Route path={mainProgramTemplatesPageRoute.path} element={mainProgramTemplatesPageRoute.element}/>
          </Route>
        </Route>

        <Route element={<AppLayout visibleSidebar={false}/>}>
          <Route path={logoutPageRoute.path} element={logoutPageRoute.element}/>
        </Route>

        <Route element={<AppLayout visibleSidebar={false} visibleHeader={false} visibleFooter={false}/>}>
          <Route path={viewMainProgramPageRoute.path} element={viewMainProgramPageRoute.element}/>
          <Route element={<AdminPageProtector/>}>
            <Route path={viewMainProgramTemplatePageRoute.path} element={viewMainProgramTemplatePageRoute.element}/>
          </Route>
          <Route path={mainProgramPageRoute.path} element={mainProgramPageRoute.element}/>
        </Route>
        <Route element={<AdminAndUserPageProtector/>}>
          <Route path={previewMainProgramPageRoute.path} element={previewMainProgramPageRoute.element}/>
        </Route>
      </Route>

      <Route path={authorizationPageRoute.path} element={authorizationPageRoute.element}/>

      <Route element={<AppLayout visibleSidebar={false}/>}>
        <Route path={notFoundPageRoute.path} element={notFoundPageRoute.element}/>
      </Route>
    </Routes>
  );
};
