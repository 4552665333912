import {Outlet} from "react-router-dom";
import {useAppLocation} from "services/navigation/use-location";
import {useUserProfileRole} from "common/use-user-profile-role";
import {PageProtectorBase} from "./Base/PageProtectorBase";

export const AdminAndUserPageProtector = () => {
  const location = useAppLocation();
  const {isUser, isAdmin} = useUserProfileRole();

  return (
    <PageProtectorBase to={"/page-not-found"} condition={isUser || isAdmin}
                       state={{from: location}}>
      <Outlet/>
    </PageProtectorBase>
  );
};