import {AppTableControls} from "common/TableControls/TableControls";
import {AppText} from "shared/UI/UIKit/Text";
import {AppExternalLink} from "shared/UI/UIKit/Link";
import type {MouseEvent} from "react";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {MainProgram} from "services/api";

export const getMainProgramsTableColumns = (
  removeMainProgram: (record: MainProgram) => void,
  editMainProgram: (record: MainProgram) => void,
  isObserver: boolean
): AppTableColumn<MainProgram>[] => {
  const columns: AppTableColumn<MainProgram>[] = [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "educationLevel",
      title: "Уровень образования",
      dataIndex: ["educationLevel"],
    },
    {
      key: "name",
      title: "Название",
      dataIndex: ["name"],
      render: (value: any, record: MainProgram) => {
        return (
          <AppText>
            {
              record.name ? (
                <>{record.name}</>
              ) : (
                <>Образовательная программа {record.educationLevel}</>
              )
            }
          </AppText>
        );
      }
    },
    {
      key: "createTs",
      type: "datetime",
      title: "Дата создания",
      dataIndex: ["createTs"],
    },
    {
      key: "createdBy",
      title: "Автор",
      dataIndex: ["createdBy"],
    },
    {
      key: "linkToSite",
      title: "Ссылка на сайт",
      dataIndex: ["linkToSite"],
      maxWidth: "20rem",
      render: (value?: string) => {
        return value ? (
          <AppExternalLink style={{
            color: "#006EB8",
            minWidth: "20rem",
            padding: "1rem",
          }} href={value} target="_blank" onClick={e => e.stopPropagation()}>
            {value}
          </AppExternalLink>
        ) : null;
      }
    }
  ];

  const controlsColumn: AppTableColumn<MainProgram> = {
    key: "controls",
    title: "",
    dataIndex: [],
    maxWidth: "5rem",
    onCell: () => {
      return {
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
        },
      };
    },
    render: (value: any, record: MainProgram) => {
      const removeProgram = () => removeMainProgram(record);
      const editProgram = () => editMainProgram(record);

      return !isObserver ? (
        <AppTableControls edit={editProgram} remove={removeProgram}/>
      ) : null;
    }
  };

  if (!isObserver) {
    columns.push(controlsColumn);
  }

  return columns;
};