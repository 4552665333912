export type UserProfileRegion = {
  id: number | string;
  name: string;
}

export type UserProfileMunicipality = {
  id: number;
  name: string;
  region: UserProfileRegion;
}

export type UserProfileOrganization = {
  id: number;
  name: string;
  fullName: string;
  municipality: UserProfileMunicipality;
}

export type UserProfile = {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  password: string;
  emailVerificationToken: string;
  passwordRecoveryToken: string;
  processingOfPersonalData: boolean;
  emailConfirmed: boolean;
  profileActivated: boolean;
  avatar: string;
  region: UserProfileRegion;
  municipality: UserProfileMunicipality;
  organization: UserProfileOrganization;
  role: UserProfileRole;
};

export enum UserProfileRole {
  ADMIN = "ADMIN",
  USER = "USER",
  OBSERVER = "OBSERVER"
}