import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "services/auth";
import {getHomeUrl} from "services/navigation";
import {useAppLocation} from "services/navigation/use-location";

export const AuthorizationPage = () => {
  const {login, authenticated} = useAuth();
  const navigate = useNavigate();
  const location = useAppLocation();
  const from = location.state?.from?.pathname;
  const keycloakRedirectUri = `${window.location.origin}${from ? from : getHomeUrl()}`;

  useEffect(() => {
    if (!authenticated) {
      login(keycloakRedirectUri);
    } else {
      navigate(getHomeUrl());
    }
  }, [authenticated]);

  return null;
};
