import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  createMainProgramBlock,
  getDetailedCurriculumById,
  getMainProgram,
  getMainProgramBlockById, getMainProgramBlockDiff,
  getWorkProgramById, PROGRAM_TEMPLATE_BLOCK_TYPE, removeMainProgramBlock, renameCurriculum, updateMainProgramBlock
} from "services/api";
import {getNumericFieldComparator} from "shared/helpers/utils/comparators";
import type {RootState} from "services/redux/types";
import type {MainProgramBlockDtoBody, ProgramTemplateBlock} from "services/api";
import {prepareTableContent, processWidgets, replaceVariables} from "./utils";

export const getMainProgramByIdAction = createAsyncThunk("mainProgram/getMainProgram", async(id: string) => {
  try {
    return await getMainProgram(id);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getMainProgramBlockDiffAction = createAsyncThunk("mainProgram/getMainProgramDiff", async(id: string) => {
  try {
    return await getMainProgramBlockDiff(id);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getWorkProgramByIdAction = createAsyncThunk("mainProgram/getMainProgramPart", async(partId: string) => {
  try {
    const response = await getWorkProgramById(partId);

    const sortedBlocks = response.blocks.sort(getNumericFieldComparator((item) => item.index, "ascend"));
    const isLessonPlanning = (item: ProgramTemplateBlock) => item.name.replaceAll(" ", "").toLowerCase().includes("поурочноепланирование");

    // убираем Титульный лист
    sortedBlocks.splice(0, 1);

    // временное решение - позже бэк будет присылать актуальные данные
    // Убираем поурочное планирование
    if (sortedBlocks.find(item => isLessonPlanning(item))) {
      const lessonPlaningIndex = sortedBlocks.findIndex(item => isLessonPlanning(item));
      sortedBlocks.splice(lessonPlaningIndex, 1);
    }
    // временное решение - позже бэк будет присылать актуальные данные

    return {
      ...response,
      blocks: sortedBlocks
    };
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getMainProgramBlockByIdAction = createAsyncThunk("mainProgram/getMainProgramBlock", async(body: {id: string, isTemplate?: boolean}, thunkAPI) => {
  const {profileState, mainProgramState} = (thunkAPI.getState() as RootState);
  try {
    const block = await getMainProgramBlockById(body.id);

    return {
      ...block,
      content: block.type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE ?
        prepareTableContent(block.content, mainProgramState.mainProgram) :
        processWidgets(replaceVariables(block.content, profileState.profile, mainProgramState.mainProgram), body.isTemplate),
    };
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getDetailedCurriculumByIdAction = createAsyncThunk("mainProgram/getDetailedCurriculum", async(id: string, thunkAPI) => {
  const {mainProgram} = (thunkAPI.getState() as RootState).mainProgramState;
  try {
    return await getDetailedCurriculumById(id, mainProgram!.id);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const createMainProgramBlockAction = createAsyncThunk("mainProgramBlock/create", async(body: MainProgramBlockDtoBody) => {
  try {
    return await createMainProgramBlock(body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const updateMainProgramBlockAction = createAsyncThunk("mainProgramBlock/update", async(body: MainProgramBlockDtoBody) => {
  try {
    return await updateMainProgramBlock(body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const deleteMainProgramBlockAction = createAsyncThunk("mainProgramBlock/delete", async(id: number) => {
  try {
    await removeMainProgramBlock(id);
    return id;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const renameCurriculumAction = createAsyncThunk("mainProgram/renameCurriculum", async(name: string, thunkAPI) => {
  const {detailedCurriculum} = (thunkAPI.getState() as RootState).mainProgramState;
  try {
    return await renameCurriculum(Number(detailedCurriculum!.title), name);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});


