import type {CurriculumDetailed, MainProgram, MainProgramBlockDto, ProgramTemplate} from "services/api";

export type MainProgramState = {
  loading: boolean;
  mainProgram: MainProgram | null;
  mainProgramPartLoading: boolean;
  workProgram: ProgramTemplate | null;
  mainProgramBlock: MainProgramBlockDto | null;
  mainProgramBlockDiffContent: string | null;
  mainProgramBlockDiffContentLoading: boolean;
  detailedCurriculum: CurriculumDetailed | null;
  renderType: RenderMainProgramType | null;
}

export enum RenderMainProgramType {
  MAIN_PROGRAM_BLOCK = "MAIN_PROGRAM_BLOCK",
  WORK_PROGRAM = "WORK_PROGRAM",
  CURRICULUM = "CURRICULUM",
}