import {useKeycloak} from "@react-keycloak/web";
import {createContext, useEffect, useState} from "react";
import {noop} from "shared/helpers/utils/noop";
import {getProfileAction} from "services/redux/reducers/profile";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {AppLoader} from "shared/UI/UIKit/Loader";
import type {PropsWithChildren} from "shared/types";

type AuthContextType = {
  login: (redirectUri: string) => void;
  logout: () => void;
  authenticated: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  login: noop,
  logout: noop,
  authenticated: false,
});

export const AuthContextProvider = (props: PropsWithChildren) => {
  const {keycloak} = useKeycloak();
  const {profile} = useAppSelector(state => state.profileState);
  const dispatch = useAppDispatch();
  const shouldFetchProfile = !!keycloak.authenticated;
  const [isProfileFetching, setProfileFetching] = useState(shouldFetchProfile);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setProfileFetching(true);
        await dispatch(getProfileAction()).unwrap();
      } catch {
        keycloak.logout();
      } finally {
        setProfileFetching(false);
      }
    };

    if (shouldFetchProfile) {
      fetchProfile();
    }
  }, [shouldFetchProfile]);

  return (
    <AuthContext.Provider value={{
      login: (redirectUri) => {
        keycloak.login({redirectUri});
      },
      logout: keycloak.logout,
      authenticated: !!keycloak.authenticated && !!profile,
    }}>
      {isProfileFetching ? <AppLoader size="large" stretched/> : props.children}
    </AuthContext.Provider>
  );
};

