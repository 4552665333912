import {useNavigate, useParams} from "react-router-dom";
import {Fragment, useContext, useEffect, useMemo, useRef} from "react";
import saveAs from "file-saver";
import classNames from "classnames";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {AppLoader} from "shared/UI/UIKit/Loader";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {useModal, usePayloadedModal} from "shared/UI/UIKit/Modal";
import {MainProgramBlockModal} from "features/main-program/components/BlockModal";
import {RemoveMainProgramBlockModal} from "features/main-program/components/RemoveModal/Remove";
import {VisibleIndexContext} from "features/main-program/context/visible-index-context";
import {RenameCurriculumModal} from "features/main-program/components/RenameCurriculumModal";
import {AddNewElementModal} from "features/main-program/components/AddNewElementModal";
import {getPreviewMainProgramUrl, getViewMainProgramUrl} from "services/navigation";
import {copyMainProgram, getConvertedDocument, PROGRAM_TEMPLATE_BLOCK_TYPE} from "services/api";
import {AppTitle} from "shared/UI/UIKit/Title";
import {useCheckDocx} from "features/main-program/components/MainProgram/use-check-docx";
import {DeleteElementModal} from "features/main-program/components/DeleteElementModal";
import {ChangeWorkProgramModal} from "features/main-program/components/ChangeWorkProgramsModal";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {MainProgramBlockDto} from "services/api";
import type {ViewMainProgramPageParams} from "services/navigation";
import {
  clearMainProgramBlockDiffContentAction,
  clearMainProgramStateAction,
  setMainProgramLoadingAction
} from "../../redux/slice";
import {RenderMainProgramType} from "../../redux/types";
import {MainProgramBlockViewer} from "../MainProgramBlockViewer";
import {
  getDetailedCurriculumByIdAction,
  getMainProgramBlockByIdAction, getMainProgramBlockDiffAction,
  getMainProgramByIdAction,
  getWorkProgramByIdAction
} from "../../redux/actions";
import {WorkProgramViewer} from "../WorkProgramViewer";
import {
  selectMainProgram,
  selectMainProgramBlock, selectMainProgramBlockDiffContent,
  selectMainProgramBlockDiffContentLoading,
  selectMainProgramLoading,
  selectRenderType,
} from "../../redux/selectors";
import {CurriculumViewer} from "../CurriculumViewer";
import styles from "./styles.module.scss";

type MainProgramProps = {
  isTemplate?: boolean;
}

export const MainProgram = (props: MainProgramProps) => {
  const {mainProgramId, mainProgramPartId} = useParams<ViewMainProgramPageParams>();
  const {isIndexesVisible, toggleIsIndexesVisible} = useContext(VisibleIndexContext);
  const dispatch = useAppDispatch();
  const renderType = useAppSelector(selectRenderType);
  const loading = useAppSelector(selectMainProgramLoading);
  const mainProgram = useAppSelector(selectMainProgram);
  const mainProgramBlock = useAppSelector(selectMainProgramBlock);
  const mainProgramBlockDiffContentLoading = useAppSelector(selectMainProgramBlockDiffContentLoading);
  const mainProgramBlockDiffContent = useAppSelector(selectMainProgramBlockDiffContent);
  const {state: blockModalState, methods: blockModalMethods} = usePayloadedModal<MainProgramBlockDto>();
  const {state: removeBlockModalState, methods: removeBlockModalMethods} = usePayloadedModal<MainProgramBlockDto>();
  const {state: renameCurriculumModalState, methods: renameCurriculumModalMethods} = useModal();
  const {state: addNewElementModalState, methods: addNewElementModalMethods} = useModal();
  const {state: deleteElementModalState, methods: deleteElementModalMethods} = useModal();
  const {state: changeWorkProgramsModalState, methods: changeWorkProgramsModalMethods} = useModal();
  const navigate = useNavigate();
  const firstRequest = useRef(true);
  const {pdfConvertStatus} = useCheckDocx();
  const {isAdmin, isObserver} = useUserProfileRole();

  const generateDocx = () => {
    if (mainProgramId && mainProgramPartId) {
      navigate(getPreviewMainProgramUrl({mainProgramId, mainProgramPartId}));
      dispatch(clearMainProgramStateAction());
    }
  };

  const showDiff = () => {
    dispatch(getMainProgramBlockDiffAction(mainProgramPartId!));
  };

  const hideDiff = () => {
    dispatch(clearMainProgramBlockDiffContentAction());
  };

  const copyOop = () => {
    dispatch(setMainProgramLoadingAction(true));
    copyMainProgram(mainProgramId!).then(res => {
      dispatch(setMainProgramLoadingAction(false));
      const titleBlock = res.blocks.find(block => block.name === "Титульный лист");

      navigate(getViewMainProgramUrl({
        mainProgramId: String(res.id),
        mainProgramPartId: String(titleBlock?.id) || String(res.items[0].workProgram.id)
      }));
    }).catch(() => {
      dispatch(setMainProgramLoadingAction(false));
    });
  };

  const downloadDocument = async (type: "pdf" | "docx") => {
    if (pdfConvertStatus !== "DONE") {
      return;
    }

    const file = await getConvertedDocument(mainProgramId!, type);
    saveAs(file, `document.${type}`);
  };

  useEffect(() => {
    if (mainProgramId) {
      dispatch(getMainProgramByIdAction(mainProgramId))
        .unwrap()
        .then(res => {
          const isBlock = res.blocks.find(block => block.id === Number(mainProgramPartId));
          const isCurriculum = res.curriculums?.find(curriculum => curriculum.id === Number(mainProgramPartId));
          if (isBlock) {
            dispatch(getMainProgramBlockByIdAction({id: mainProgramPartId!, isTemplate: props.isTemplate}));
          } else if (isCurriculum) {
            dispatch(getDetailedCurriculumByIdAction(mainProgramPartId!));
          } else {
            dispatch(getWorkProgramByIdAction(mainProgramPartId!));
          }

          firstRequest.current = false;
        });
    }
  }, [mainProgramId, props.isTemplate]);

  useEffect(() => {
    if (mainProgramPartId && !firstRequest.current) {
      const isBlock = mainProgram!.blocks.find(block => block.id === Number(mainProgramPartId));
      const isCurriculum = mainProgram!.curriculums?.find(curriculum => curriculum.id === Number(mainProgramPartId));
      if (isBlock) {
        dispatch(getMainProgramBlockByIdAction({id: mainProgramPartId!, isTemplate: props.isTemplate}));
      } else if (isCurriculum) {
        dispatch(getDetailedCurriculumByIdAction(mainProgramPartId!));
      } else {
        dispatch(getWorkProgramByIdAction(mainProgramPartId!));
      }
    }
  }, [mainProgramPartId, props.isTemplate]);

  useEffect(() => {
    return () => {
      dispatch(clearMainProgramStateAction());
    };
  }, []);

  useEffect(() => {
    dispatch(clearMainProgramBlockDiffContentAction());
  }, [mainProgramPartId]);

  const content = useMemo(() => {
    switch (renderType) {
      case RenderMainProgramType.WORK_PROGRAM:
        return <WorkProgramViewer/>;
      case RenderMainProgramType.MAIN_PROGRAM_BLOCK:
        return <MainProgramBlockViewer/>;
      case RenderMainProgramType.CURRICULUM:
        return <CurriculumViewer/>;
      default:
        return null;
    }
  }, [renderType]);

  const getDownloadButtonText = (type: string) => {
    if (pdfConvertStatus === "DONE") {
      return `Скачать ${type}`;
    }

    if (pdfConvertStatus === "PROGRESS" || pdfConvertStatus === "NEW") {
      return `Обработка ${type}`;
    }
  };

  if (loading) {
    return <AppLoader stretched/>;
  }

  return (
    <AppFlex unit="16">
      <AppTitle level={3}>{mainProgram?.name || `Образовательная программа ${mainProgram?.educationLevel}`}</AppTitle>
      <AppFlex direction="row" justifyContent="flex-end">
        {
          props.isTemplate ? (
            <>
              <AppButton onClick={() => blockModalMethods.show()}>
                Создать блок
              </AppButton>
              <AppButton onClick={toggleIsIndexesVisible}>
                {isIndexesVisible ? "Скрыть порядковые номера" : "Показать порядковые номера"}
              </AppButton>
              {
                renderType === RenderMainProgramType.MAIN_PROGRAM_BLOCK && mainProgramBlock && (
                  <>
                    <AppButton onClick={() => blockModalMethods.show(mainProgramBlock)}>
                      Редактировать настройки блока
                    </AppButton>
                    <AppButton onClick={() => removeBlockModalMethods.show(mainProgramBlock)}>
                      Удалить
                    </AppButton>
                  </>
                )
              }
            </>
          ) : (
            <Fragment>
              {
                renderType === RenderMainProgramType.WORK_PROGRAM && !mainProgram?.linkToSite && !isObserver && (
                  <AppButton onClick={changeWorkProgramsModalMethods.show}>
                    Изменить рабочую программу
                  </AppButton>
                )
              }
              {
                renderType === RenderMainProgramType.MAIN_PROGRAM_BLOCK &&
                mainProgramBlock?.name !== "Титульный лист" &&
                mainProgramBlock?.type !== PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE &&
                (isObserver || isAdmin) && (
                  <>
                    {
                      mainProgramBlockDiffContent ? (
                        <AppButton onClick={hideDiff}>
                          Показать оригинал
                        </AppButton>
                      ) : (
                        <AppButton onClick={showDiff} loading={mainProgramBlockDiffContentLoading}>
                          Показать изменения
                        </AppButton>
                      )
                    }
                  </>
                )
              }
              {
                !mainProgram?.linkToSite && !mainProgramBlockDiffContent && !isObserver && (
                  <>
                    <AppButton onClick={addNewElementModalMethods.show}>
                      Добавить элемент
                    </AppButton>
                    <AppButton onClick={deleteElementModalMethods.show}>
                      Удалить элемент
                    </AppButton>
                  </>
                )
              }
              {
                (pdfConvertStatus === "DONE" || pdfConvertStatus === "PROGRESS" || pdfConvertStatus === "NEW") && !mainProgramBlockDiffContent && !isObserver && (
                  <>
                    <AppButton loading={pdfConvertStatus === "PROGRESS" || pdfConvertStatus === "NEW"}
                               onClick={() => downloadDocument("docx")}>
                      {getDownloadButtonText("docx")}
                    </AppButton>
                    <AppButton loading={pdfConvertStatus === "PROGRESS" || pdfConvertStatus === "NEW"}
                               onClick={() => downloadDocument("pdf")}>
                      {getDownloadButtonText("pdf")}
                    </AppButton>
                  </>
                )
              }
              {
                pdfConvertStatus !== "PROGRESS" && pdfConvertStatus !== "NEW" && !mainProgramBlockDiffContent && !isObserver && (
                  <AppButton onClick={generateDocx}>
                    Сгенерировать PDF
                  </AppButton>
                )
              }
              {
                !mainProgramBlockDiffContent && !isObserver && (
                  <AppButton onClick={copyOop}>
                    Скопировать ООП
                  </AppButton>
                )

              }
            </Fragment>
          )
        }
        {
          renderType === RenderMainProgramType.CURRICULUM && !mainProgram?.linkToSite && !isObserver && (
            <AppButton onClick={() => renameCurriculumModalMethods.show()}>
              Переименовать учебный план
            </AppButton>
          )
        }
      </AppFlex>
      <AppFlex alignItems="center" className={classNames(
        styles.container,
        isObserver && !mainProgramBlockDiffContent && styles.observerView,
        mainProgramBlockDiffContent && styles.diffContent
      )}>
        {content}
      </AppFlex>
      <MainProgramBlockModal visible={blockModalState.visible}
                             hideModal={blockModalMethods.hide}
                             isTemplate={props.isTemplate}
                             selectedBlock={blockModalState.payload}/>
      <RemoveMainProgramBlockModal visible={removeBlockModalState.visible}
                                   hideModal={removeBlockModalMethods.hide}
                                   selectedBlock={removeBlockModalState.payload}
                                   isTemplate={props.isTemplate}/>
      <RenameCurriculumModal visible={renameCurriculumModalState.visible}
                             hideModal={renameCurriculumModalMethods.hide}/>
      <AddNewElementModal visible={addNewElementModalState.visible}
                          hideModal={addNewElementModalMethods.hide}/>
      <DeleteElementModal visible={deleteElementModalState.visible}
                          hideModal={deleteElementModalMethods.hide}/>
      <ChangeWorkProgramModal visible={changeWorkProgramsModalState.visible}
                              hideModal={changeWorkProgramsModalMethods.hide}/>
    </AppFlex>
  );
};